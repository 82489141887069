const validationConfig = {
  formSelector: '.form',
  inputSelector: '.form__input',
  submitButtonSelector: '.form__send-button',
  inactiveButtonClass: 'form__send-button_disabled',
  inputErrorClass: 'form__input_error',
  errorClass: 'form__input-error_visible',
};

export default validationConfig;
