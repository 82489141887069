import gsap from './libs/gsap.min.js';
import Observer from './libs/Observer.min';

import { widthSize1280, widthSize1281 } from './constants/widthSize.js';

export const NEXT = 1;
const PREV = -1;
export const impactButton = document.getElementById('impact__button_slide');

// Slideshow module
class Slideshow {
  constructor(DOM_el) {
    let DOM = {
      el: null,
      slides: null,
      slidesInner: null,
    };
    let current = 0;
    let slidesTotal = 0;
    let isAnimating = false;

    const donateSlideNumber = 6;

    // Initialize slideshow
    function init() {
      DOM.el = DOM_el;
      DOM.slides = [...DOM_el.querySelectorAll('.slide')];
      DOM.slidesInner = DOM.slides.map((item) =>
        item.querySelector('.slide__target'),
      );
      slidesTotal = DOM.slides.length;
      DOM.slides[current].classList.add('slide--current');
    }

    // Navigate to next slide
    this.next = function () {
      navigate(NEXT);
    };

    // Navigate to previous slide
    this.prev = function () {
      navigate(PREV);
    };

    // Navigate through slides
    function navigate(direction) {
      if (isAnimating) return false;
      isAnimating = true;

      const previous = current;
      current =
        direction === 1
          ? current < slidesTotal - 1
            ? ++current
            : 0
          : current > 0
            ? --current
            : slidesTotal - 1;

      const currentSlide = DOM.slides[previous];
      const currentInner = DOM.slidesInner[previous];
      const upcomingSlide = DOM.slides[current];
      const upcomingInner = DOM.slidesInner[current];

      gsap
        .timeline({
          defaults: {
            duration: 1.4,
            // duration: 3,
            ease: 'power4.inOut',
          },
          onStart: () => {
            upcomingSlide.classList.add('slide--current');
          },
          onComplete: () => {
            currentSlide.classList.remove('slide--current');
            isAnimating = false;
          },
        })
        .addLabel('start', 0)
        .to(
          currentSlide,
          {
            yPercent: -direction * 100,
          },
          'start',
        )
        .to(
          currentInner,
          {
            yPercent: direction * 30,
          },
          'start',
        )
        .fromTo(
          upcomingSlide,
          {
            yPercent: direction * 100,
          },
          {
            yPercent: 0,
          },
          'start',
        )
        .fromTo(
          upcomingInner,
          {
            yPercent: -direction * 30,
          },
          {
            yPercent: 0,
          },
          'start',
        );
    }

    init();

    impactButton.addEventListener('click', () => {
      current = donateSlideNumber;
    });
  }
}

let mm = gsap.matchMedia();

export const initializeSlideshow = () => {
  mm.add(
    {
      isMobile: `(max-width: ${widthSize1280}px)`,
      isDesktop: `(min-width: ${widthSize1281}px)`,
    },
    (ctx) => {
      // eslint-disable-next-line no-unused-vars
      let { isMobile, isDesktop } = ctx.conditions;

      if (isDesktop) {
        gsap.registerPlugin(Observer);
        const slides = document.querySelector('.slides');
        const slideElements = slides.querySelectorAll('.slide');
        const slideshow = new Slideshow(slides);

        // Auxiliary function for getting the index of the current slide
        const getCurrentIndex = () => {
          return Array.from(slideElements).findIndex((slide) =>
            slide.classList.contains('slide--current'),
          );
        };

        // A function to handle scrolling down
        const handleScrollDown = () => {
          const currentIndex = getCurrentIndex();
          if (
            currentIndex < slideElements.length - 1 &&
            !document.querySelector('.active')
          ) {
            slideshow.next();
          }
        };

        // A function to handle scrolling up
        const handleScrollUp = () => {
          const currentIndex = getCurrentIndex();
          if (currentIndex > 0) {
            slideshow.prev();
          }
        };

        Observer.create({
          type: 'wheel, touch',
          wheelSpeed: -1, // inverting the mouse wheel
          tolerance: 10,
          onDown: handleScrollUp,
          onUp: handleScrollDown,
        });
      }
    },
  );
};
