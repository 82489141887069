import { initializeSlideshow, impactButton, NEXT } from './slideshow.js';
import { initializeSmoothScroll } from './modules/smoothScroll.js';
import { initializeSwiper } from './swiper.js';
import enableValidation from './modules/enableValidation.js';
import validationConfig from './modules/validationConfig.js';
import send from './modules/send';
import { scrollToDonate } from './modules/scrollToDonate.js';
import linearAnimations from './modules/linearAnimations.js';

window.addEventListener('DOMContentLoaded', () => {
	initializeSlideshow();
	initializeSmoothScroll();
	initializeSwiper();
	enableValidation(validationConfig);
	send(
		'.form',
		'input[name="name"]',
		'input[name="email"]',
		'/mailer/mail.php',
	);
	impactButton.addEventListener('click', () =>
		scrollToDonate(
			'#impact',
			'.impact__container',
			'#donate',
			'.donate__container',
			NEXT,
		),
	);
	linearAnimations();
});
