import gsap from './../libs/gsap.min.js';

export const scrollToDonate = function (
  curSlide,
  curInner,
  comingSlide,
  comingInner,
  direction,
) {
  let isAnimating = false;

  if (isAnimating) return false;
  isAnimating = true;

  const currentSlide = document.querySelector(curSlide);
  const currentInner = document.querySelector(curInner);
  const upcomingSlide = document.querySelector(comingSlide);
  const upcomingInner = document.querySelector(comingInner);

  if (window.innerWidth >= 1281) {
    gsap
      .timeline({
        defaults: {
          duration: 1.2,
          ease: 'power4.inOut',
        },
        onStart: () => {
          upcomingSlide.classList.add('slide--current');
        },
        onComplete: () => {
          currentSlide.classList.remove('slide--current');
          isAnimating = false;
        },
      })
      .addLabel('start', 0)
      .to(
        currentSlide,
        {
          yPercent: -direction * 100,
        },
        'start',
      )
      .to(
        currentInner,
        {
          yPercent: direction * 30,
        },
        'start',
      )
      .fromTo(
        upcomingSlide,
        {
          yPercent: direction * 100,
        },
        {
          yPercent: 0,
        },
        'start',
      )
      .fromTo(
        upcomingInner,
        {
          yPercent: -direction * 30,
        },
        {
          yPercent: 0,
        },
        'start',
      );
  }
};
