import gsap from './../libs/gsap.min.js';
import ScrollTrigger from './../libs/ScrollTrigger.min.js';

import { widthSize1280 } from './../constants/widthSize';

const linearAnimations = () => {
  gsap.registerPlugin(ScrollTrigger);

  const about = document.querySelector('#about');
  const aboutTextWrapper = about.querySelector('.about__wrapper');

  const impact = document.querySelector('#impact');
  const impactTextWrapper = impact.querySelector('.impact__bottom');

  const ourProgrammes = document.querySelector('#our-programes');
  const ourProgrammesTextWrapper = ourProgrammes.querySelector(
    '.our-programes__content-wrapper',
  );

  const principles = document.querySelector('#principles');
  const principlesTextWrapper = principles.querySelector(
    '.principles__item-wrapper',
  );

  const animation = (element) => {
    gsap.to(element, {
      duration: 1.2,
      ease: 'power4.out',
      stagger: 0.2,
      opacity: 1,
      y: 0,
    });
  };

  let observer;

  function initObserver(block, wrapper, element) {
    if (window.innerWidth > widthSize1280) {
      observer = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            block.classList.contains('slide--current')
          ) {
            observer.unobserve(wrapper);

            animation(element);
          }
        },
        { threshold: 1.0 },
      );
      observer.observe(wrapper);
    } else {
      return;
    }
  }

  initObserver(about, aboutTextWrapper, '.about__description');
  initObserver(impact, impactTextWrapper, '.impact__description');
  initObserver(
    ourProgrammes,
    ourProgrammesTextWrapper,
    '.our-programes__program',
  );
  initObserver(principles, principlesTextWrapper, '.principles__item');
};

export default linearAnimations;
