import Swiper from './libs/swiper-bundle.min.js';

export const initializeSwiper = () => {
  const swiper = new Swiper('.mission__swiper', {
    slidesPerView: 3,
    observer: true,
    observeSlideChildren: true,
    updateOnWindowResize: true,
    observeParents: true,
    speed: 500,
    grabCursor: true,
    breakpoints: {
      320: {
        grid: {
          rows: 4,
          fill: 'row',
        },
        slidesPerView: 1,
      },
      769: {
        grid: {
          rows: 1,
          fill: 'row',
        },
        slidesPerView: 3,
      },
    },
  });

  return swiper; // if you need access to the swiper object
};
