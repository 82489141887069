import enableValidation from './enableValidation';
import validationConfig from './validationConfig';

const send = (formClass, name, email, mailerPath) => {
	const forms = document.querySelectorAll(`${formClass}`);

	forms.forEach((form) => {
		const formWrapper = form.querySelector('.form__wrapper');
		const serverResponce = form.querySelector('.form__responce');
		const sendAgain = form.querySelector('.form__button_responce');
		const sendButton = form.querySelector('.form__send-button');
		const inputName = form.querySelector(name);
		const inputEmail = form.querySelector(email);
		const popupTitle = form.querySelector('.form__title');

		sendButton.addEventListener('click', function (event) {
			console.log('Click send');

			if (!inputName.value || !inputEmail.value) {
				event.preventDefault();
			} else {
				form.addEventListener('submit', async function (event) {
					console.log('Submit');
					event.preventDefault();

					let response = await fetch(mailerPath, {
						method: 'POST',
						body: new FormData(form),
					});

					if (response.ok) {
						console.log('Enjoy');
						formWrapper.classList.add('form__wrapper_unvisible');
						serverResponce.classList.add('form__responce_visible');
						sendButton.classList.add('form__button_unvisible');
						popupTitle.classList.add('form__title_unvisible');
					} else {
						console.log('Fail');
						formWrapper.classList.add('form__wrapper_unvisible');
						serverResponce.classList.add('form__responce_visible');
						sendButton.classList.add('form__button_unvisible');
						popupTitle.classList.add('form__title_unvisible');
					}
				});
			}
		});

		sendAgain.addEventListener('click', () => {
			formWrapper.classList.remove('form__wrapper_unvisible');
			serverResponce.classList.remove('form__responce_visible');
			sendButton.classList.remove('form__button_unvisible');
			popupTitle.classList.remove('form__title_unvisible');

			form.reset();

			enableValidation(validationConfig);
		});
	});
};

export default send;
