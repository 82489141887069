import { EMAIL_REGEXP } from '../constants/regexp';

function hideInputError(
	formElement,
	inputElement,
	{ inputErrorClass, errorClass },
) {
	const errorElement = formElement.querySelector(`.${inputElement.id}-error`);
	errorElement.classList.remove(errorClass);
	errorElement.textContent = '';
	inputElement.classList.remove(inputErrorClass);
}

function showInputError(
	formElement,
	inputElement,
	{ inputErrorClass, errorClass },
) {
	const errorElement = formElement.querySelector(`.${inputElement.id}-error`);
	errorElement.classList.add(errorClass);
	errorElement.textContent = inputElement.validationMessage;
	inputElement.classList.add(inputErrorClass);
}

function checkInputValidity(formElement, inputElement, config) {
	let customValidityMessage = '';

	if (inputElement.value === '') {
		customValidityMessage = 'required field';
	} else if (
		inputElement.type === 'email' &&
		!validateEmail(inputElement.value)
	) {
		customValidityMessage = 'Invalid email format';
	}

	inputElement.setCustomValidity(customValidityMessage);

	if (!inputElement.validity.valid) {
		if (inputElement.value !== '' && customValidityMessage === '') {
			inputElement.setCustomValidity('error');
		}
		showInputError(formElement, inputElement, config);
	} else {
		hideInputError(formElement, inputElement, config);
	}
}

function validateEmail(email) {
	return EMAIL_REGEXP.test(email);
}

function hasInvalidState(inputList) {
	return inputList.some(
		(inputElement) =>
			!inputElement.validity.valid ||
			(inputElement.type === 'email' && !validateEmail(inputElement.value)),
	);
}

function toggleButtonState(inputList, buttonElement, { inactiveButtonClass }) {
	if (hasInvalidState(inputList)) {
		buttonElement.classList.add(inactiveButtonClass);
		buttonElement.disabled = true;
	} else {
		buttonElement.classList.remove(inactiveButtonClass);
		buttonElement.disabled = false;
	}
}

function setEventListeners(formElement, config) {
	const inputList = Array.from(
		formElement.querySelectorAll(config.inputSelector),
	);
	const buttonElement = formElement.querySelector(config.submitButtonSelector);

	toggleButtonState(inputList, buttonElement, config);

	inputList.forEach((inputElement) => {
		inputElement.addEventListener('input', () => {
			checkInputValidity(formElement, inputElement, config);
			toggleButtonState(inputList, buttonElement, config);
		});
	});

	formElement.addEventListener('submit', (event) => {
		if (hasInvalidState(inputList)) {
			event.preventDefault();
		}
	});
}

function enableValidation(config) {
	const formList = Array.from(document.querySelectorAll(config.formSelector));
	formList.forEach((formElement) => {
		setEventListeners(formElement, config);
	});
}

export default enableValidation;
