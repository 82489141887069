import SmoothScroll from './../libs/SmoothScroll.min.js';

export function initializeSmoothScroll() {
  SmoothScroll({
    // Scroll time 400 = 0.4 seconds
    animationTime: 700,
    // Step size in pixels
    stepSize: 80,

    // Additional settings:

    // Boost
    accelerationDelta: 20,
    // Maximum acceleration
    accelerationMax: 1,

    // Keyboard support
    keyboardSupport: true,
    // The step of scrolling with the arrows on the keyboard in pixels
    arrowScroll: 50,

    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,

    // Touchpad support
    touchpadSupport: true,
  });
  // console.log(smoothScroll);
  // return smoothScroll;
  // if you need access to the SmoothScroll object
}
